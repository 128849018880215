import React, { useEffect, useRef } from "react";
import Video from "../assets/gcasHero.mp4";

function Hero() {
	const videoRef = useRef(null);

	useEffect(() => {
		const video = videoRef.current;
		if (video) {
			const playPromise = video.play();
			if (playPromise !== undefined) {
				playPromise
					.then(() => {
						console.log("Video played successfully");
					})
					.catch((error) => {
						console.log("Video play was prevented:", error);
						// Attempt to play video again with a slight delay
						setTimeout(() => {
							video.play();
						}, 1000);
					});
			}
		}
	}, []);

	return (
		<section id="hero" className="desktopMaxWidth hero">
			<div className="videoContainer">
				<div className="colorOverlay"></div>
				<p className="textOverlay">Coming Together is the Beginning</p>
				<video ref={videoRef} src={Video} autoPlay loop muted playsInline />
			</div>
		</section>
	);
}

export default Hero;
